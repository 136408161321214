import { createContext, useCallback, useState } from "react";

import { CacheProvider } from "@emotion/react";

import createEmotionCache from "./cache";

export const CSSCacheContext = createContext<(currentKey?: string) => void>(
  () => void 0,
);

export const ResetCacheProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [cache, setCache] = useState(createEmotionCache());
  const reset = useCallback(() => setCache(createEmotionCache()), []);
  return (
    <CSSCacheContext.Provider value={reset}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </CSSCacheContext.Provider>
  );
};
